<template>
    <div :style="{background:bgc}" >
       <div class="type_area" style="display:flex;padding:10px 56px 20px ">
         <div v-for="(item,index) in direct" :key="index">
            <div style="color:#666666;font-size:16px;" :class="{'blue':index===direct.length-1}">{{item}}<span style="margin:0 5px;display:inline-block;" v-if="index!==direct.length-1">/</span></div>
        </div>
       </div>
    </div>
</template>

<script>
export default {
    props: [
        'direct',
        'bgc'
    ],
    data() {
        return {

        }
    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>
.blue {
    color: #1A9DFF !important;
}
</style>>

