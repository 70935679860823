<template>
    <div>
        <div class="banner" v-if="backgroundUrl">
            <img style="width:100%;height:340px;" :src="backgroundUrl" alt="">
        </div>
        <div class="online_order ">
            <Bread v-if="!backgroundUrl" :direct="direct"></Bread>
            <div class="brand_title">{{title}}</div>
            <!-- 商品列表-网格模式 -->
            <div class="goods_box type_area" v-loading="loading">
                <div class="box_list" v-if="goodsList.length">
                    <div class="goods_item" v-for="(item,index) in goodsList" :key="item.goodsId" @click="goodsDetail(item.goodsId)">
                        <!-- 商品图片区 -->
                        <div class="goods_img">
                            <img :src="item.thumbUrl" alt="">
                            <img v-if="item.stock == 0" class="nostock" src="@/assets/images/index/nostock.png" alt="">
                            <div class="tag">
                                <span :style="item1.actType == 2||item1.actType == 4?'':'display: none'" :class="{tj:item1.actType == 2,yh:item1.actType == 4}" v-for="(item1,index1) in item.acts" :key="index1">{{item1.name}}</span>
                            </div>
                        </div>
                        <!-- 商品信息区 -->
                        <div class="goods_info">
                            <div class="title">{{item.productName}}</div>
                            <div class="spesc">
                                <img src="@/assets/images/index/goods_gg.svg" alt="">
                                <el-tooltip class="item" effect="dark" :disabled="item.norms&&item.norms.length<30" :content="item.norms" placement="top-start">
                                    <span>{{item.norms}}</span>
                                </el-tooltip>
                            </div>
                            <div class="spesc">
                                <img src="@/assets/images/index/goods_cj.svg" alt="">
                                <span>{{item.produceUnit?item.produceUnit:'--'}}</span>
                            </div>
                            <div class="spesc">
                                <img src="@/assets/images/index/goods_xq.svg" alt="">
                                <span>优于{{item.lastDate?item.lastDate:'--'}}</span>
                            </div>
                            <div style="display:flex;align-items:center;padding-left:10px;margin:10px 0 0px;">
                                <div class="price" v-html="initPrice(item.jiage)"></div>
                                <div v-if="item.jiage!=item.price" style="font-size: 10px;color: #ACACAC;text-decoration-line: line-through;margin-left:5px;">原价{{item.price}}</div>
                            </div>
                            <div class="gross">
                                <span>毛利率 {{item.grossMargin?item.grossMargin:'--'}}</span>
                                <span>零售价 ￥{{(item.retailPrice).toFixed(2)}}</span>
                            </div>
                        </div>
                        <!-- 商品加购收藏区 -->
                        <div class="goods_purchase" @click.stop="">
                            <div class="purchase_top" :style="$route.query.prepareOrderNumber?'justify-content: space-between;':'justify-content: flex-start;'">
                                <div v-if="!$route.query.prepareOrderNumber" class="collect" @click="favoriteClick(item.goodsId,goodsList,index)">
                                    <img v-if="item.favorite" src="@/assets/images/index/collect_a.svg" alt="">
                                    <img v-else src="@/assets/images/index/collect.svg" alt="">
                                </div>
                                <el-input-number class="number" v-model="item.buyNumber" @change="addNumberChange(item)" :disabled="item.stock == 0" size="small" :min="item | minStep" :step="item | minStep" :precision="0"></el-input-number>
                                <div v-if="item.cartNumber>0&&!$route.query.prepareOrderNumber" class="add exist" :class="{disabled:item.stock == 0}" @click="addPurchase(item)">
                                    <span>已在采购车</span>
                                </div>
                                <div v-else class="add" :class="{disabled:item.stock == 0}" @click="addPurchase(item)">
                                    <span>{{$route.query.prepareOrderNumber?'加入订单':'加入采购车'}}</span>
                                </div>
                            </div>
                            <span>库存{{item.stock}}</span>
                        </div>
                    </div>
                </div>
                <div class="nodata_box" v-else>
                    <img src="@/assets/images/index/nodata.png" alt="">
                    <span>暂无数据哦~</span>
                </div>
            </div>
            <div class="pagination_box" v-if="total>0">
                <el-pagination background layout="prev, pager, next, sizes, total" :page-sizes="[10, 20, 30, 40]" :page-size.sync="filterinfo.pageSize" :current-page.sync="filterinfo.page" :total="total" @current-change="getGoodsSearch(1)" @size-change="getGoodsSearch(2)">
                </el-pagination>
            </div>
            <cartDialog v-if="cartList.length" @realAddCar="realAddCar" @closeDialog="closeDialog" :countStep="goodsItem| minStep" :cartList="cartList" :farenVisible="farenVisible"></cartDialog>
            <priceDialog v-if="tipVisible" @tipClose="tipClose" @closePriceDialog="noBuy" :priceList="tipList" :tipVisible="tipVisible"></priceDialog>

        </div>
    </div>
</template>
<script>
import { getCarNum, getOrderNum } from '@/utils/carNumber'
import cartDialog from '@/views/goodsDetail/component/cartDialog.vue'
import priceDialog from '@/views/shopCar/component/priceDialog.vue'
import SortTool from '@/components/Sort'
import Bread from '@/components/Bread'
export default {
    name: 'OnlineOrder',
    components: {
        SortTool,
        Bread,
        cartDialog,
        priceDialog
    },
    data() {
        return {
            loading: false,
            productList: [],//产品筛选列表
            brandList: [],//品牌筛选列表
            priceList: [],//价格筛选列表
            tagsList: [],//标签筛选列表
            floorList: [],//楼层筛选列表
            total: 0,
            tipVisible: false,
            cartList: [],//
            tipList: [],//
            reserveId: null,//预定商品id
            filterinfo: {
                page: 1,
                pageSize: 10,
            },
            goodsList: [],//搜索商品列表
            backgroundUrl: '',
            farenVisible: false,
            title: '',
            goodsItem: '',
            goodsId: '',
            direct: ['首页', '专题'],

        };
    },
    filters: {
        minStep(item) {
            let num = 1
            item.Pack.forEach(item1 => {
                if (item1.isPack == 'on') {
                    num = item1.num
                }
            })
            return Number(num)
        }
    },

    mounted() {
        this.getGoodsSearch()
    },
    deactivated() {
        // window.removeEventListener('resize', this.handleResize);
    this.tipVisible = false;
        
  },
    // beforeRouteEnter(to, from, next) {
    //     next((vm) => {
    //         if (from.path != '/goodsDetail') {
    //             vm.resetClick()
    //         }
    //     });
    // },
    methods: {
        closeDialog() {
            this.farenVisible = false
        },
        tipClose() {
            this.tipVisible = false
        },
        noBuy() {
            this.tipVisible = false
        },
        async realAddCar(cartList) {
            const arr = cartList.filter((item) => item.checked && item.quantity)
            if (!arr.length) return this.$message('请选择要购买的门店')

            const cartMultipleMemberNum = arr.map(item => {
                return {
                    memberId: item.memberId,
                    num: item.quantity,
                }
            })
            console.log(cartMultipleMemberNum);
            const res = await this.$api.multipleAddCart({
                goodsId: this.goodsId,
                cartMultipleMemberNum
            })
            console.log(res);
            if (res.data.code == 200) {
                const faliList = res.data.data.filter(item => item.cartNoticeResp[0].state != 200)
                if (!faliList.length) {
                    this.$message({
                        message: '加入采购车成功',
                        type: 'success'
                    })
                } else {
                    if (cartMultipleMemberNum.length == faliList.length) {
                        this.$notify({
                            title: '提示',
                            message: faliList.map(item => item.companyName + item.cartNoticeResp[0].message).join('\n'),
                            type: 'warning'
                        });
                    } else {
                        this.$notify({
                            title: '提示',
                            message: faliList.map(item => item.companyName + item.cartNoticeResp[0].message).join('\n') + '，其余客户加入购物车成功',
                            type: 'warning'
                        });
                    }
                }
            }
            getCarNum(this)
            this.farenVisible = false

        },
        // 获取搜索列表
        getGoodsSearch(num) {
            if (num != 1 && num != 2) {
                this.filterinfo.page = 1
                this.filterinfo.pageSize = 10
            }
            if (num == 2) this.filterinfo.page = 1
            if (this.$route.query.id) this.filterinfo.id = this.$route.query.id
            this.loading = true
            this.$api.pcActivity(this.filterinfo).then((res) => {
                this.loading = false
                if (res.data.code === 200) {
                    this.goodsList = res.data.data.list ? res.data.data.list : []
                    this.total = res.data.data.total
                    this.backgroundUrl = res.data.data.backgroundUrl
                    this.title = res.data.data.title
                } else {
                    // this.$toast(res.data.msg)
                }
            }).catch(error => {
                // this.loading = false
                // this.$toast("请求异常，请稍后重试！");
            });
        },


        // 页面跳转
        goodsDetail(id) {
            if (this.$route.query.prepareOrderNumber) {
                this.$router.push('/goodsDetail?goodsId=' + id + '&type=audit&prepareOrderNumber=' + this.$route.query.prepareOrderNumber)
            } else {
                this.$router.push('/goodsDetail?goodsId=' + id)
            }
        },
        // // 价格区间确定按钮
        // priceConfirm() {
        //     if (this.price1 === undefined || this.price2 === undefined) return this.$message({ message: '请输入完整的价格区间', type: "warning" })
        //     let price = this.price1 + "~" + this.price2
        //     this.filterClick('价格', price)
        //     this.price1 = undefined
        //     this.price2 = undefined
        // },
        // 收藏点击
        favoriteClick(id, list, index) {
            let params = {
                goodsId: id
            }
            this.$api.pcProductDoFav(params).then((res) => {
                if (res.data.code === 200) {
                    list[index].favorite = list[index].favorite == true ? false : true
                    list = list.filter(item => item)
                    let msg = list[index].favorite ? '收藏成功' : '取消收藏成功'
                    this.$toast(msg);
                }
            });
        },
        // 加购输入框限制
        addNumberChange(item) {
            var number = 1
            item.Pack.forEach(item1 => {
                if (item1.isPack == 'on') {
                    number = item1.num
                }
            })
            if (item.buyNumber % number != 0) {
                this.$toast('加购数量必须为中包装数量倍数！');
                item.buyNumber = number
            }
        },
        // 加入采购车
        async addPurchase(item) {
            console.log(item);

            this.goodsId = item.goodsId
            this.goodsItem = item
            if (this.$store.state.memberInfo.isMain == 1) {
                const res = await this.$api.storeList({ goodsId: item.goodsId })
                console.log(res);
                if (res.data.code == 200) {

                    res.data.data.multipleMemberInfo.forEach(item => {
                        item.checked = false
                        item.quantity = 0
                    })
                    this.cartList = res.data.data.multipleMemberInfo
                    console.log(this.cartList);

                    this.farenVisible = true

                }

            } else {
                if (item.stock == 0) {
                    this.$toast('该商品即将到货！');
                } else {
                    let params = {
                        goodsId: item.goodsId,
                        num: item.buyNumber,
                        actType: 1,
                        actId: 0,
                    }
                    if (this.$route.query.prepareOrderNumber) params.prepareOrderNumber = this.$route.query.prepareOrderNumber
                    this.$api.pcJoinCar(params).then((res) => {
                        if (res.data.code === 200) {

                            if (res.data.data.state === 200) {
                                this.$toast(res.data.data.message);
                                // 成功加入采购车后按钮状态变成已在采购车
                                item.cartNumber = item.buyNumber
                            } else if (res.data.data.state == 300102) {
                                this.tipList = [{
                                    goodsId:item.goodsId,
                                    productName: item.productName
                                }]
                                this.tipVisible = true
                            } else {
                                this.$message({
                                    message: res.data.data.message,
                                    type: "warning"
                                })
                            }
                            // 更新采购车数量
                            getCarNum(this)
                        }
                    });
                }
            }

        },
    }
};
</script>
<style lang="scss" scoped>
.online_order {
    .brand_title {
        font-weight: bold;
        font-size: 20px;
        color: #333333;
        padding: 16px 0 20px;
        text-align: center;
    }
    .fx {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin: 0 10px;
    }
    .activefx {
        color: #0687fe;
    }
    .filter_area {
        background: #fff;
        padding: 5px 16px;
        margin-top: 14px;
        border-radius: 2px 2px 2px 2px;

        .filter_box {
            .filter_item {
                padding: 10px 0;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                font-size: 12px;
                border-bottom: 1px dashed #dfdfdf;

                &:last-of-type {
                    border: none;
                }

                &.brand {
                    // height: 37px;
                    padding: 4px 0;
                    position: relative;
                    .item_right {
                        .filter_text {
                            position: relative;
                            margin-right: 10px;
                            border-radius: 2px 2px 2px 2px;
                            overflow: hidden;
                            border: 1px solid transparent;

                            img {
                                width: 80px;
                                height: 28px;
                                vertical-align: middle;
                            }

                            &:hover {
                                border: 1px solid #1a9dff;
                            }

                            &.active {
                                border: 1px solid #1a9dff;

                                &::after {
                                    content: '';
                                    position: absolute;
                                    top: 0;
                                    right: 0;
                                    width: 13px;
                                    height: 14px;
                                    background: url('~@/assets/images/order/brand_a.svg');
                                }
                            }
                        }
                    }

                    .more {
                        position: absolute;
                        right: 0;
                        top: 8px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 54px;
                        height: 24px;
                        box-sizing: border-box;
                        padding-left: 3px;
                        border-radius: 2px 2px 2px 2px;
                        border: 1px solid #e8e8e8;
                        font-size: 12px;
                        color: #999999;
                        cursor: pointer;

                        &:hover {
                            color: #60bbff;
                            border-color: #60bbff;
                            transition: 0.3s;
                        }

                        .icon {
                            font-size: 14px;
                            transition: 0.3s;

                            &.active {
                                transform: rotate(180deg);
                            }
                        }
                    }
                }

                &.price {
                    padding: 8px 0;
                    .number_box {
                        display: flex;
                        align-items: center;

                        ::v-deep {
                            .el-input-number.is-controls-right
                                .el-input-number__decrease,
                            .el-input-number.is-controls-right
                                .el-input-number__increase {
                                display: none;
                            }
                            .el-input-number.is-controls-right
                                .el-input__inner {
                                padding: 0;
                                font-size: 12px;
                            }
                            .el-input__inner,
                            .el-input-number {
                                width: 40px;
                                height: 20px;
                                line-height: 20px;
                                border-radius: 2px;
                            }
                        }

                        .btn {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 40px;
                            height: 20px;
                            margin-left: 12px;
                            background: #1a9dff;
                            border-radius: 2px 2px 2px 2px;
                            font-size: 12px;
                            color: #ffffff;
                            cursor: pointer;

                            &:hover {
                                opacity: 0.9;
                            }
                        }
                    }
                }

                &.filter {
                    padding: 9px 0;
                    height: 38px;
                    .item_right {
                        .filter_text {
                            padding: 2px 6px;
                            background: #e9f6ff;
                            color: #1a9dff;
                            border-radius: 2px 2px 2px 2px;
                            margin-right: 7px;
                            cursor: auto;

                            .icon {
                                color: #74c3ff;
                                margin-left: 3px;
                                font-weight: 600;
                                font-size: 11px;
                                cursor: pointer;

                                &:hover {
                                    color: #b3deff;
                                }
                            }
                        }
                    }
                }

                .filter_title {
                    flex-shrink: 0;
                    display: inline-block;
                    width: 72px;
                    color: #999999;
                }

                .item_right {
                    width: 1034px;
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;

                    .filter_text {
                        display: inline-block;
                        color: #333333;
                        margin-right: 18px;
                        cursor: pointer;

                        &:hover,
                        &.active {
                            color: #1a9dff;
                        }
                    }
                }
            }
        }
    }
    .sieving_tool {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 36px;
        background: #fff;
        margin: 20px 0;

        .tool_left {
            display: flex;
            align-items: center;

            .sieving_box {
                display: flex;
                align-items: center;

                .sieving_item {
                    width: 72px;
                    height: 36px;
                    line-height: 36px;
                    text-align: center;
                    font-size: 14px;
                    color: #666666;
                    cursor: pointer;
                    margin-right: 1px;

                    &.active,
                    &.active:hover {
                        background: #1a9dff;
                        color: #fff;
                    }

                    &:hover {
                        color: #1a9dff;
                    }
                }
            }

            .search_box {
                width: 232px;

                ::v-deep {
                    .el-input__inner {
                        border-radius: 100px 0 0 100px;
                        border-color: #ebebeb;
                        height: 26px;
                        line-height: 26px;
                        font-size: 12px;
                        color: #333;
                    }
                    .el-input-group__append {
                        border-radius: 0 100px 100px 0;
                        border-color: #ebebeb;
                        padding: 0;
                        background: #fff;
                        font-size: 12px;
                        color: #333333;
                        cursor: pointer;
                    }
                    .el-input__suffix {
                        top: -6px;
                    }
                }
            }

            .sort_habit {
                margin: 0 38px 0 20px;
            }
        }
        .tool_right {
            display: flex;
            align-items: center;
            padding-right: 12px;

            .right_item {
                padding: 4px;
                cursor: pointer;
                transition: 0.3s;
                &:hover .wgzs,
                &.active .wgzs {
                    background: url('~@/assets/images/order/wgzs_a.svg');
                }
                &:hover .lbzs,
                &.active .lbzs {
                    background: url('~@/assets/images/order/lbzs_a.svg');
                }
                &:hover .bgzs,
                &.active .bgzs {
                    background: url('~@/assets/images/order/bgzs_a.svg');
                }
            }
            .wgzs {
                width: 13px;
                height: 13px;
                background: url('~@/assets/images/order/wgzs.svg');
            }
            .lbzs {
                width: 13px;
                height: 13px;
                background: url('~@/assets/images/order/lbzs.svg');
            }
            .bgzs {
                width: 13px;
                height: 13px;
                background: url('~@/assets/images/order/bgzs.svg');
            }
        }
    }
    .goods_box {
        .box_list {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .goods_item {
                position: relative;
                width: 285px;
                height: 440px;
                box-sizing: border-box;
                margin: 0 20px 20px 0;
                background: #ffffff;
                border-radius: 2px 2px 2px 2px;
                cursor: pointer;

                &:hover {
                    .goods_purchase {
                        opacity: 1;
                        visibility: visible;
                    }
                }

                &:nth-of-type(4n) {
                    margin-right: 0;
                }

                .goods_img {
                    position: relative;
                    text-align: center;
                    margin-bottom: 4px;

                    .nostock {
                        width: 132px;
                        height: 132px;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        right: 0;
                        left: 0;
                        margin: auto;
                    }

                    img {
                        width: 285px;
                        height: 285px;
                        vertical-align: middle;
                    }

                    .tag {
                        position: absolute;
                        left: 10px;
                        bottom: 0;
                        display: flex;
                        align-items: center;

                        span {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 33px;
                            height: 21px;
                            box-sizing: border-box;
                            border-radius: 3px 3px 3px 3px;
                            font-size: 12px;
                            line-height: 0px;
                        }

                        .tj {
                            background: rgba(248, 213, 213, 0.9);
                            border: 1px solid #ffacac;
                            color: #f92b2b;
                            margin-right: 4px;
                        }
                        .zy {
                            background: rgba(206, 231, 249, 0.9);
                            border: 1px solid #b7dcf8;
                            color: #1a9dff;
                            margin-right: 4px;
                        }
                        .yh {
                            width: auto;
                            padding: 0 6px;
                            background: rgba(248, 240, 223, 0.9);
                            border: 1px solid #fad894;
                            color: #f7a400;
                        }
                    }
                }

                .goods_info {
                    padding: 0 10px;

                    .title {
                        font-weight: bold;
                        font-size: 16px;
                        color: #333333;
                        margin-bottom: 6px;
                        white-space: nowrap; /* 不换行 */
                        overflow: hidden; /* 超出部分隐藏 */
                        text-overflow: ellipsis; /* 溢出部分显示省略号 */
                    }
                    .spesc {
                        display: flex;
                        align-items: center;
                        font-size: 12px;
                        color: #666666;
                        margin-bottom: 5px;

                        span {
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        img {
                            margin-right: 3px;
                        }
                    }
                    .invent {
                        color: #666666;
                    }
                    .price {
                        color: #f34141;
                        font-weight: bold;
                        font-size: 16px;
                        margin-bottom: 3px;
                    }
                    .gross {
                        display: inline-block;
                        padding: 6px 17px 6px 7px;
                        background: #ecf6ff;
                        border-radius: 2px 2px 2px 2px;
                        font-size: 12px;
                        color: #4e6474;
                        line-height: 0px;
                        width: 236px;
                        box-sizing: border-box;

                        span {
                            &:first-of-type::after {
                                content: '';
                                display: inline-block;
                                width: 1px;
                                height: 10px;
                                background: #c4d4e2;
                                margin: 0 6px 0 9px;
                            }
                        }
                    }
                }

                .goods_purchase {
                    opacity: 0;
                    visibility: hidden;
                    transition: 0.5s;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    box-sizing: border-box;
                    padding: 8px 10px;
                    background: rgba(0, 0, 0, 0.3);
                    font-size: 12px;
                    color: #ffffff;
                    cursor: auto;

                    .purchase_top {
                        display: flex;
                        align-items: center;
                        margin-bottom: 8px;

                        .collect {
                            width: 30px;
                            height: 30px;
                            box-sizing: border-box;
                            margin-right: 25px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background: #f5f7fa;
                            border-radius: 4px 4px 4px 4px;
                            border: 1px solid #ffffff;
                            cursor: pointer;
                        }
                        .number {
                            width: 108px;
                            height: 30px;
                            margin-right: 14px;
                            margin-top: -3px;

                            ::v-deep {
                                .el-input__inner {
                                    height: 30px;
                                    line-height: 30px;
                                    background: #f5f7fa;
                                    border: 1px solid #fff;
                                    color: #333;
                                    font-weight: 600;
                                    font-size: 15px;
                                }
                                .el-input-number__decrease,
                                .el-input-number__increase {
                                    width: 26px;
                                    top: 0;
                                    height: 28px;
                                    background: #ecedf0;
                                    border: 1px solid #fff;
                                    color: #333;
                                }
                                .el-icon-plus,
                                el-icon-minus {
                                    font-weight: 600;
                                }
                                .el-input-number__decrease.is-disabled,
                                .el-input-number__increase.is-disabled {
                                    background: #e1e4e9;
                                }
                                .el-input__inner {
                                    padding-left: 22px;
                                    padding-right: 22px;
                                }
                            }
                        }

                        .add {
                            width: 86px;
                            height: 30px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background: linear-gradient(
                                181deg,
                                #1a9dff 0%,
                                #0687fe 100%
                            );
                            border-radius: 4px 4px 4px 4px;
                            font-size: 14px;
                            color: #ffffff;

                            &:hover {
                                background: linear-gradient(
                                    181deg,
                                    #4daef6 0%,
                                    #0687fe 100%
                                );
                                cursor: pointer;
                                transition: 0.3s;
                            }
                            &.disabled,
                            &.disabled:hover {
                                background: linear-gradient(
                                    181deg,
                                    #86c8f9 0%,
                                    #7cbdf9 100%
                                );
                            }
                            &.exist {
                                background: linear-gradient(
                                    182deg,
                                    #31c19e 0%,
                                    #14ac87 100%
                                );

                                &:hover {
                                    background: linear-gradient(
                                        182deg,
                                        #14ac87 0%,
                                        #31c19e 100%
                                    );
                                    cursor: pointer;
                                    transition: 0.3s;
                                }
                                &.disabled,
                                &.disabled:hover {
                                    background: linear-gradient(
                                        182deg,
                                        #91dac8 0%,
                                        #89d6c3 100%
                                    );
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .list_mode {
        .goods_list {
            .list_item {
                position: relative;
                padding: 13px 16px;
                margin-bottom: 12px;
                background: #fff;
                display: flex;
                align-items: flex-start;

                .goods_img {
                    position: relative;
                    margin-right: 18px;
                    cursor: pointer;

                    .nostock {
                        width: 119px;
                        height: 119px;
                        position: absolute;
                        top: 10px;
                        right: 0;
                        left: 0;
                        margin: 0 auto;
                    }
                    img {
                        width: 150px;
                        height: 150px;
                        vertical-align: middle;
                    }
                }

                .goods_info {
                    .title {
                        display: flex;
                        align-items: center;
                        font-weight: bold;
                        font-size: 16px;
                        color: #333333;
                        margin-bottom: 10px;
                        .tag {
                            display: flex;
                            align-items: center;

                            span {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: 30px;
                                height: 17px;
                                box-sizing: border-box;
                                border-radius: 2px;
                                font-size: 10px;
                                line-height: 17px;
                                margin-right: 4px;
                            }

                            .tj {
                                background: rgba(248, 213, 213, 0.9);
                                border: 1px solid #ffacac;
                                color: #f92b2b;
                            }
                            .zy {
                                background: rgba(206, 231, 249, 0.9);
                                border: 1px solid #b7dcf8;
                                color: #1a9dff;
                            }
                            .yh {
                                width: auto;
                                padding: 0 6px;
                                background: rgba(248, 240, 223, 0.9);
                                border: 1px solid #fad894;
                                color: #f7a400;
                            }
                        }
                    }
                    .info_box {
                        .info_item {
                            display: flex;
                            align-items: center;
                            font-size: 14px;
                            color: #333333;
                            margin-bottom: 3px;

                            & > div {
                                width: 320px;
                                margin-right: 16px;
                            }

                            .info_name {
                                color: #999999;
                                margin-right: 12px;
                            }
                        }
                    }

                    .bottom {
                        display: flex;
                        align-items: center;
                        margin-top: 7px;

                        .price_box {
                            font-size: 20px;
                            color: #f34141;
                            font-weight: 600;
                        }

                        .yh_box {
                            position: relative;
                            margin-left: 10px;
                            padding-bottom: 2px;
                            .yh {
                                width: auto;
                                padding: 2px 6px;
                                background: rgba(248, 240, 223, 0.9);
                                border: 1px solid #fad894;
                                border-radius: 4px;
                                font-size: 12px;
                                color: #f7a400;
                            }
                            .sj {
                                position: absolute;
                                left: -4px;
                                top: 8px;
                            }
                        }
                    }
                }

                .goods_collect {
                    position: absolute;
                    top: 11px;
                    right: 19px;
                    width: 28px;
                    height: 28px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: rgba(0, 0, 0, 0.2);
                    border-radius: 4px 4px 4px 4px;
                    cursor: pointer;
                }
                .goods_add {
                    position: absolute;
                    bottom: 17px;
                    right: 34px;
                    display: flex;
                    align-items: center;

                    .number {
                        width: 117px;
                        height: 40px;
                        margin-right: 31px;
                        margin-top: -3px;

                        ::v-deep {
                            .el-input__inner {
                                height: 40px;
                                line-height: 40px;
                                background: #ffffff;
                                border: 1px solid #f0f0f0;
                                font-size: 18px;
                                color: #333;
                                font-weight: 600;
                            }
                            .el-input-number__decrease,
                            .el-input-number__increase {
                                width: 31px;
                                top: 0;
                                height: 38px;
                                line-height: 38px;
                                background: #f5f7fa;
                                border: 1px solid #f0f0f0;
                                color: #333;
                            }
                            .el-icon-plus,
                            el-icon-minus {
                                font-weight: 600;
                            }
                            .el-input-number__decrease.is-disabled,
                            .el-input-number__increase.is-disabled {
                                background: #e1e4e9;
                            }
                            .el-input__inner {
                                padding-left: 33px;
                                padding-right: 33px;
                            }
                        }
                    }

                    .add {
                        width: 142px;
                        height: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: linear-gradient(
                            182deg,
                            #1a9dff 0%,
                            #0687fe 100%
                        );
                        border-radius: 4px 4px 4px 4px;
                        font-size: 18px;
                        color: #ffffff;

                        img {
                            margin-right: 8px;
                        }

                        &:hover {
                            background: linear-gradient(
                                181deg,
                                #4daef6 0%,
                                #0687fe 100%
                            );
                            cursor: pointer;
                            transition: 0.3s;
                        }
                        &.disabled,
                        &.disabled:hover {
                            background: linear-gradient(
                                181deg,
                                #86c8f9 0%,
                                #7cbdf9 100%
                            );
                        }

                        &.exist {
                            background: linear-gradient(
                                182deg,
                                #31c19e 0%,
                                #14ac87 100%
                            );

                            &:hover {
                                background: linear-gradient(
                                    182deg,
                                    #14ac87 0%,
                                    #31c19e 100%
                                );
                                cursor: pointer;
                                transition: 0.3s;
                            }
                            &.disabled,
                            &.disabled:hover {
                                background: linear-gradient(
                                    182deg,
                                    #91dac8 0%,
                                    #89d6c3 100%
                                );
                            }
                        }
                    }
                }
            }
        }
    }
    .table_mode {
        .name {
            &:hover {
                cursor: pointer;
                color: #1a9dff;
            }
        }
        .add_box {
            display: flex;
            align-items: center;

            ::v-deep {
                .el-input-number.is-controls-right .el-input-number__decrease,
                .el-input-number.is-controls-right .el-input-number__increase {
                    display: none;
                }
                .el-input-number.is-controls-right .el-input__inner {
                    padding: 0;
                    font-size: 18px;
                    color: #333;
                    font-weight: 600;
                }
                .el-input__inner,
                .el-input-number {
                    width: 72px;
                    height: 30px;
                    line-height: 30px;
                    border-radius: 2px;

                    &::placeholder {
                        color: #c7c3c3;
                        font-size: 12px;
                    }
                }
            }

            .btn_item {
                width: 106px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 8px;
                background: linear-gradient(182deg, #1a9dff 0%, #0687fe 100%);
                border-radius: 4px 4px 4px 4px;
                font-size: 18px;
                color: #ffffff;
                cursor: pointer;

                &.add {
                    &:hover {
                        background: linear-gradient(
                            181deg,
                            #4daef6 0%,
                            #0687fe 100%
                        );
                        cursor: pointer;
                        transition: 0.3s;
                    }

                    &.exist {
                        background: linear-gradient(
                            182deg,
                            #31c19e 0%,
                            #14ac87 100%
                        );

                        &:hover {
                            background: linear-gradient(
                                182deg,
                                #14ac87 0%,
                                #31c19e 100%
                            );
                            cursor: pointer;
                            transition: 0.3s;
                        }
                        &.disabled,
                        &.disabled:hover {
                            background: linear-gradient(
                                182deg,
                                #91dac8 0%,
                                #89d6c3 100%
                            );
                        }
                    }
                }

                &.active {
                    background: linear-gradient(
                        182deg,
                        #f7a400 0%,
                        #f6b73d 100%
                    );
                }
            }
        }
    }
}

.dialog_box {
    ::v-deep {
        .el-dialog {
            border-radius: 10px 10px 10px 10px;
        }
        .el-dialog__header {
            padding: 18px 16px;
            font-weight: bold;
            font-size: 18px;
            color: #1a1a1a;
        }
        .el-dialog__body {
            padding: 0 25px 0;
        }
        .el-dialog__footer {
            padding: 4px 25px 20px;
        }
    }
    .dialog_main {
        font-size: 14px;
        color: #1a1a1a;

        .form_item {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 10px;

            & > span {
                margin-bottom: 8px;
            }

            ::v-deep {
                .el-input-number .el-input__inner {
                    padding: 0 10px;
                    text-align: left;
                }
                .el-input-number__decrease,
                .el-input-number__increase {
                    display: none;
                }
            }
        }
    }
    .dialog_footer {
        ::v-deep {
            .el-button {
                font-size: 16px;
                padding: 10px 33px;
            }
        }
    }
}
.nodata_box {
    width: 1198px;
}
:deep(.el-tabs__nav-wrap:after) {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0px; // 改为0
    background-color: var(--el-border-color-light);
    z-index: var(--el-index-normal);
}
:deep(.el-tabs__item) {
    line-height: 50px;
}
</style>